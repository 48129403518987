.review-payment-container {
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  margin: auto;

}

.reviewpayment-title {

  margin-bottom: 30px;
  font-family: 'Open Sans', sans-serif; /* Set font to Open Sans */
  font-weight: 500 !important;
  font-size: 20px !important;
}

.reviewpayment-companyname {
  display: flex;
  justify-content: flex-start;
  font-size: 16px;
  gap:120px;
  margin-bottom: 20px;
}

.reviewpayment-section {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
  gap:50px;
}

.upgrade-reviewpayment-details {
  flex: 1;
  border: 1px solid #46CF64;
  padding: 50px 15px;
  border-radius: 8px;
  max-height: 250px; /* Adjust height to cover only the three items */
  overflow: hidden; /* Ensure content doesn't overflow */
  
}
.upgrade-reviewpayment-summary {
  flex: 1;
  border: 1px solid #ffffff;
  padding: 15px;
  border-radius: 8px;
}

.upgrade-reviewpayment-plan-box h4 {
  font-size: 16px;
  margin-bottom: 15px;
  font-weight: bold; /* Makes label text bold */
}

.upgrade-reviewpayment-plan-box p {
  font-size: 14px;
  margin-bottom: 5px;
}

.reviewpayment-additionaltext {
  font-size: 12px;
  color: #888;
}

.reviewpayment-total{
  font-size: 16px;
}
.reviewpayment-summary-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  font-size: 14px;
}


.reviewpayment-terms {
  font-size: 14px;
  color: #666;
  margin-top: 20px;
}

.reviewpayment-terms a {
  color: #46CF64;
}

.reviewpayment-buttons {
  display: flex;
  justify-content: flex-end;
  gap:20px;
  margin-top: 20px;
}

.reviewpayment-cancel, .reviewpayment-submit {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
  font-family: 'Inter', sans-serif; /* Updated to use Inter font */
}

.reviewpayment-cancel {
  background-color: #f0f0f0;
  color: #333;
}

.reviewpayment-submit {
  background-color: #00cc66;
  color: white;
  font-weight: 550;
}

.reviewpayment-submit:hover {
  background-color: #00b359;
}


.reviewpayment-cancel {
  background-color: #fff;
  color: #46CF64;
  border: 2px solid #46CF64;
  font-size: 14px;
  border-radius: 5px;
  cursor: pointer;
  padding: 0.5rem 1.6rem;
 
}

.reviewpayment-item {
  display: flex;
  margin-bottom: 10px; /* Space between rows */
}

.reviewpayment-item,
.reviewpayment-summary-row {
    display: flex;
    align-items: center; /* Aligns items vertically */
    margin-bottom: 22px; /* Adds space between items */
}

.summary-label {
  width: 180px; 
  margin-right: 20px; 
  color: #686868;
  font-family: 'Open Sans', sans-serif; /* Set font to Open Sans */
  font-size: 14px; /* Set font size to 13px */
}
.summary-review-label{
  width: 200px; 
  margin-right: 40px; 
  color: #686868;
  font-family: 'Open Sans', sans-serif; /* Set font to Open Sans */
  font-size: 14px; /* Set font size to 13px */
}

.summary-value {
    flex: 1; /* Takes the remaining space */
    font-family: 'Open Sans', sans-serif;
    font-weight: 500;
}
