.outstandingMessage-popup-body {
    margin-top: 17.5px;
}

.outstandingMessage-popup-items{
    color: black;
    font-size: 20px;
    justify-content: center;
}

.outstandingMessage-popup-header{
    color: black;
    font-size: 22.5px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 100%;
    justify-content: space-between;
    font-weight: 500;
    margin-top: -10px;
    position: relative;
}

.dx-icon-custom-styleOutstanding {
    font-size: 28.5px !important;  
    // margin-right: 50px;
    // transform: translate(-50% -50%);
}

.outstandingMessage-popup-content {
    font-size: 17px;    
    font-weight: 450;
    width: 100%;
}

.outstandingMessage-popup-footer{
    margin-top: 10px;
    display: grid;
    grid-template-columns: auto auto;
    column-gap: 50px;
}

.outstandingMessage-popup-cross{
    font-size: 20px;
    left: 92.5%;
    position: absolute;
}

.outstandingMessage-popup-cross .dx-icon-close{
    color: black !important;
    font-size: 22.5px !important;
}

.outstandingMessage-popup-limits{
    display: grid;
    grid-template-columns: 50% 50%;
}