// .errorMessage {
//     margin-left: 10px;
//     color: red;
//     align-items: center;
//     margin-top: -27.5px;
// }

.errorMessage-popup-container{
    background-color: #FF6969 !important;
    border: 3px solid #FF1919 !important;
}

.warningMessage-popup-container{
    background-color: #FFB169 !important;
    border: 3px solid #FF8719 !important;
}

.successMessage-popup-container{
    background-color: #80C382 !important;
    border: 3px solid #2FB21B !important;
}

.errorMessage-popup-body {
    display: flex;
    gap: 20px;
    align-items: center;
    padding: 8px
}

.errorMessage-popup-items{
    color: white;
    font-size: 20px;
    justify-content: center;
}

.errorMessage-popup-header{
    color: white;
    font-size: 22.5px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 100%;
    justify-content: space-between;
    font-weight: 500;
    margin-top: -10px;
}

.errorMessage-popup-body>*:first-child {
    align-self: flex-start;
    margin-top: -5px;
}

.dx-icon-custom-styleError {
    font-size: 28.5px !important;  
    // margin-right: 50px;
    // transform: translate(-50% -50%);
}

.errorMessage-popup-content {
    font-size: 17px;    
    font-weight: 450;
}