.main-container{
    display: flex;
    flex-wrap: nowrap;
}

.dx-drawer-wrapper{
    background-color: white;
    padding-top: 70px;
    position: fixed;
    // z-index: 499;
}

.dx-drawer.dx-widget.dx-visibility-change-handler.dx-drawer-opened.dx-drawer-shrink.dx-drawer-expand.dx-drawer-left .dx-drawer-wrapper {
    box-shadow: 15px 0px 50px rgba(237, 237, 237, 0.80);
    border-right-style: solid;
    border-right-width: 0.5px;
    border-right-color: rgba(34, 34, 34, 0.075);
    position: absolute;
}

// .side-nav-container.side-nav-mobile-false.side-nav-mobile-open-true
// .dx-drawer.dx-widget.dx-visibility-change-handler.dx-drawer-shrink.dx-drawer-expand.dx-drawer-left.dx-drawer-opened
// {
//     border-top-style: solid;
//     border-top-color: rgba(34, 34, 34, 0.075);
//     border-top-width: 1px;
// }

//header
.nav-header-container{
    border-bottom-style: solid;
    border-bottom-color: rgba(34, 34, 34, 0.075);
    border-bottom-width: 1px;
}

// .nav-header-container .user-panel-container{
//     box-shadow: 15px 0px 30px #EDEDED;
// }

.nav-header-container .header-logo-container.logo-container-white-false,
.nav-header-container .header-logo-container.logo-container-white-true{
    padding-top: 15px;
}

//user panel position
.user-panel-container .nav-header-right-section .user-panel{
    padding-top: 15px;
}


//navigation header
.nav-header-container .header-logo-container{
    width: 270px;
    display: flex;
    padding: 25px 25px 10px;
}

.no-side-nav-header{
    height: 56px;
    padding: 10px;
    display: flex;
    justify-content: space-between; /* Distributes space between items */
    align-items: center;
}


.main-content-container{
    position: fixed;
    //position of main content
    right: 0;
    padding-top: 70px;

    // .dx-card{
    //     border-radius: 0;
    // }
}

.dx-treeview .dx-treeview-toggle-item-visibility {
    right: 10px;
    left: auto;
    top: 4px;
}

// #nav-treeview{
//     position: fixed;
//     top: 70px;
//     width: 250px!important;
//     height: 100%;
// }

.menu-container .dx-treeview-node{
    font-size: 15px;
    padding-left: 0;
}

.menu-container .dx-item{
    padding: 15px 20px;
}

.menu-container .dx-item .dx-item-content span{
    //navigation span position
    padding-left: 20px;
    color: #8B8B8B;
    font-size: 13.5px;
}

//expend icon
.dx-treeview-toggle-item-visibility{
    color: #A3A3A3;
    margin-top: 7px;
    margin-right: 10px;
}

//navigation icon
.dx-item.dx-treeview-item .dx-item-content.dx-treeview-item-content .dx-icon{
    color: #A3A3A3;
    padding-left: 10px;
}

//selected font&icon color
.menu-container .dx-treeview .dx-treeview-node[aria-level="2"][aria-selected="true"] .dx-item .dx-item-content span,
.menu-container .dx-treeview .dx-treeview-node[aria-expanded="false"][aria-selected="true"] .dx-item .dx-item-content span{
    color: white !important;
}

.menu-container .dx-treeview .dx-treeview-node[aria-level="2"][aria-selected="true"] .dx-item .dx-item-content .dx-icon,
.menu-container .dx-treeview .dx-treeview-node[aria-expanded="false"][aria-selected="true"] .dx-item .dx-item-content .dx-icon{
    color: white !important;
}

//hover box color
.menu-container #nav-treeview .dx-treeview-item.dx-state-hover {
    background-color: #F2F2F2;
    font-weight: 500;
}

//hover icon&fontcolor
.menu-container #nav-treeview .dx-treeview-item.dx-state-hover .dx-item-content .dx-icon,
.menu-container #nav-treeview .dx-treeview-item.dx-state-hover .dx-item-content span
{
    color: #4D4D4D;
}

//selected box color
.menu-container .dx-treeview .dx-treeview-node[aria-level="2"] .dx-treeview-item-content {
    padding-left: 20px;
}

.menu-container #nav-treeview .dx-treeview-node.dx-state-selected{
    background-color: #46CF64!important;
    font-weight: 500;

    .dx-treeview-item{
        background-color: #46CF64;
        font-weight: 500;
    }
}

//expanded box color
.dx-treeview-node-container.dx-treeview-node-container-opened{
    background-color: #F2F2F2;
}

//expanded level 1 font color
// .dx-treeview-node.dx-treeview-item-without-checkbox[aria-level="1"][aria-expanded="true"] .dx-item.dx-treeview-item .dx-item-content.dx-treeview-item-content span{
//     color: black;
// }

// .dx-treeview-node.dx-treeview-item-without-checkbox[aria-expanded="true"][aria-level="1"] .dx-item.dx-treeview-item .dx-item-content.dx-treeview-item-content .dx-icon{
//     color: #46CF64;
// }

.menu-container .dx-treeview .dx-treeview-node[aria-expanded="true"] > :first-child{
    font-size: 15px;
    transition: all 400ms;
}

.menu-container .dx-treeview .dx-treeview-node[aria-expanded="false"]{
    transition: all 400ms;
}

.main-content-full-length-true{
    width: 100%;
    left: 0;
    transition:left 0.3s linear;
}

.main-content-full-length-false{
    width: calc(100% - 270px);
    transition: all 0.4s ease 0s;
}

.main-content-mobile-true{
    width: 100%!important;
}

.main-content-mobile-open-true{
    left: 0;
}

