/* ... */
@import url("/node_modules/@devexpress/analytics-core/dist/css/dx-analytics.common.css");
@import url("/node_modules/@devexpress/analytics-core/dist/css/dx-analytics.light.css");
@import url("/node_modules/@devexpress/analytics-core/dist/css/dx-querybuilder.css");
@import url("/node_modules/devexpress-reporting/dist/css/dx-webdocumentviewer.css");
@import url("/node_modules/devexpress-reporting/dist/css/dx-reportdesigner.css");
@import url("/node_modules/devexpress-richedit/dist/dx.richedit.css");

.popup-footer{
    margin-top: 10px;
    text-align: right;
}

#close-btn{
    margin-right: 5px;
}