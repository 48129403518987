.dx-widget.dx-button.dx-button-mode-contained.dx-button-success.dx-button-has-text{
    background-color: #46CF64;
}

.dx-widget.dx-button.dx-button-mode-outlined.dx-button-success.dx-button-has-text{
    border-color: #46CF64;
    color: #46CF64;
}

.tools-listing-datagrid {
    td[role="columnheader"], 
    .dx-command-edit.dx-command-edit-with-icons.dx-cell-focus-disabled.dx-datagrid-drag-action {
        font-size: 15px;
        font-weight: 500;
        color: #5f5f5f;
        border-bottom-style: none !important;
    }

    .dx-command-edit.dx-command-edit-with-icons {
        border-left-style: none;
    }

    .dx-datagrid-rowsview.dx-scrollable-simulated {
        border-top-style: none;
    }
}

.tools-module-popup-form .popup-group-form-item {
    padding-bottom: 15px;
}

.dx-overlay-content.dx-popup-normal.dx-popup-inherit-height.dx-resizable .dx-toolbar.dx-widget.dx-visibility-change-handler.dx-collection.dx-popup-title.dx-has-close-button{
    padding: 10px 40px;

    .dx-item-content.dx-toolbar-item-content{
        font-size: 30px;
        color: #46CF64;
        font-weight: 700;
    }
}

.defult-setting-btn-group .dx-widget.dx-button.dx-button-mode-contained.dx-button-success.dx-button-has-text[aria-label="Edit"]{
    background-color: white;
    border-color: #46CF64;
    color: #46CF64;
}

//---------Above done-----------



//Tools Module CSS
.DefaultAccount,
.DefaultJournal{

    .dx-gridbase-container .dx-datagrid-header-panel{
        display: none;
    }
}

//Fiscal Year - Start
.fiscalYear-settings {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: stretch;
    justify-content: flex-end;

    .fiscalYear-settings-datebox {
        margin-top: 5px;
        display: flex;
        align-items: center;
        justify-content: center;

        .databox-label {
            margin-right: 10px;
        }

        .fiscalYear {
            font-size: large;
            cursor: pointer;
            margin-left: 5px;
        }
    }
}

.FiscalYear {
    color: white !important;
    background-color: #46CF64 !important;

    i.dx-icon.dx-icon-plus {
        color: white !important;
    }
}

.FiscalYear:hover {
    background-color: #2c6e2b !important;
}

///////////////Fiscal Year End

//Audit Trail - Start
.DetailAction {
    padding: none !important;
    color: blue;

    .dx-button-content .dx-icon-detailslayout {
        color: blue;
    }
}

.audit-trail-listing-datagrid{

    td[role="columnheader"],
    .dx-command-edit.dx-cell-focus-disabled{
        font-size: 14px;
        font-weight: 400;
        color: black;
    }

    .dx-command-edit.dx-cell-focus-disabled{
        background-color: #efefef;
    }

    .dx-button-content{
        padding: 0 18px !important;
    }

    .dx-datagrid-rowsview.dx-datagrid-nowrap.dx-last-row-border[role="presentation"] .dx-datagrid-content .dx-datagrid-table.dx-datagrid-table-fixed td[role="gridcell"]{
        padding: 5px;
    }
}

.ViewDetailBox {
    border-left: solid 0.5px;
    border-top: solid 0.5px;
}

#audit-trial-popup {
    .audit-trail-datagrid{
        border-bottom: solid 0.5px;
    }

    h5{
        font-weight: 400;
    }

    .ViewDetailBox.dx-box-flex.dx-box.dx-widget.dx-collection{
        border-bottom-style: none;

        .dx-item.dx-box-item .dx-item-content.dx-box-item-content{
            padding-left: 5px;
            padding-top: 3px;
            border-right: solid 0.5px;
        }

    }
}

.dx-overlay-content.dx-popup-normal.dx-popup-flex-height.dx-resizable .dx-item-content.dx-toolbar-item-content{
    font-size: 20px;
    max-width: fit-content;
}

///////////Audit Trail End

//User Log -  Start
.user-log-listing-datagrid .dx-command-edit,
.dx-widget.dx-button.dx-button-mode-contained.dx-button-normal.dx-button-has-text.dx-button-has-icon.listing-page-add-btn.undefined{
    display: none !important;
}
/////////////User Log End
