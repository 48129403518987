.user-panel{
    padding-top: 10px;
}

// .dx-widget.dx-button.dx-button-mode-text.dx-button-normal.nav-user-panel .dx-button-content{

// }

.user-info{
    font-size: 16px;
}

.user-panel-separator{
    padding-left: 0;
    padding-right: 10px;
}

.nav-user-panel{
    font-size: 16px;
}

.user-panel-company-name{
    font-weight: 500;
}

.user-panel-container{
    background-color: white;
}

.user-info .dx-widget.dx-button.dx-button-mode-text.dx-button-normal.nav-user-panel{
    -webkit-tap-highlight-color: transparent;
}

//user panel dropdown
.dx-overlay-content.dx-inner-overlay.dx-context-menu.user-menu.dx-menu-base 
.dx-submenu{
    border-style: none;
    box-shadow: 2px 2px 10px lightgray;
    border-top-width: 1.5px;
    border-top-color: #46CF64;
    border-top-style: solid;
}

//user panel dropdown-hover
.dx-item.dx-menu-item.dx-menu-item-has-text.dx-menu-item-has-icon.dx-state-hover{
    background-color: #46CF64;
    color: white;
}

//user panel drop down content
.dx-item.dx-menu-item.dx-menu-item-has-text.dx-menu-item-has-icon{
    height: 45px;
}

.dx-item.dx-menu-item.dx-menu-item-has-text.dx-menu-item-has-icon 
.dx-item-content.dx-menu-item-content{
    padding: 10px 20px;
}

//dropdown icon
.dx-widget.dx-button.dx-button-mode-text.dx-button-normal.nav-user-panel 
.dx-button-content .dx-icon-chevrondown{
    padding-left: 10px;
}

//user panel
.dx-widget.dx-button.dx-button-mode-text.dx-button-normal.nav-user-panel,
.dx-item.dx-menu-item.dx-menu-item-has-text.dx-menu-item-has-icon
{
    color: #4D4D4D;
}

//user panel-hover
.dx-widget.dx-button.dx-button-mode-text.dx-button-normal.nav-user-panel.dx-state-hover{
    color: #13C57C;
}

//logout-hover
.user-info .dx-widget.dx-button.dx-button-mode-text.dx-button-normal.dx-button-has-text.logout-btn.dx-state-hover{
    -webkit-tap-highlight-color:transparent;

    .dx-button-content .dx-button-text{
        color: #46CF64;
    }
}

//logout
.user-info .dx-widget.dx-button.dx-button-mode-text.dx-button-normal.dx-button-has-text.logout-btn
.dx-button-content .dx-button-text{
    font-size: 14px;
    color: #A3A3A3;
}


//user panel separator
.user-info .user-panel-separator{
    padding-right: 0px;
    color: #A3A3A3;
}

.dx-widget.dx-button.dx-button-mode-text.dx-button-normal.nav-user-panel 
.dx-button-content{
    padding: 8px 15px 8px 15px;
}

//user panel icon
.dx-widget.dx-button.dx-button-mode-text.dx-button-normal.nav-user-panel
.dx-button-content .dx-icon-group{
    padding-right: 5px;
}

.dx-button-content .dx-icon-globe{
    font-size: 18px;
}