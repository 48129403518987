@import "../themes/generated/variables.base.scss";
@import url("/node_modules/devextreme/dist/css/dx.light.css");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700&display=swap");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.app {
  height: 100%;
  width: 100%;

  .content {
    line-height: 1.5;
    flex-grow: 1;
    width: 100%;

    h2 {
      font-size: 30px;
      margin-top: 0px;
      margin-bottom: 20px;
    }
  }

  .container {
    height: 100%;
    flex-direction: column;
    display: flex;
  }

  .layout-body {
    flex: 1;
    min-height: 0;
  }

  .content-block {
    border: none;
    width: 100%;
    padding: 0 40px;

    h3 {
      margin-block-start: 0;
      margin-block-end: 0;
    }
  }
}

html,
body {
  margin: 0px;
  min-height: 100%;
  height: 100%;
}

#root {
  height: 100%;
}

.swal2-container {
  z-index: 3000 !important;
}

//Default styling - Start

.display-inline-block {
  display: inline-block;
}

.margin-right-1 {
  margin-right: 10px;
  margin-left: 10px;
}

.dx-item.dx-toolbar-item.dx-toolbar-label {
  max-width: fit-content;
}

.form-action-dropdown {
  .dx-icon-spindown {
    color: white !important;
  }

  .dx-button-content {
    color: white !important;
    background-color: #daa410;
  }

  .dx-button-content:hover {
    background-color: #be941f;
  }
}

.form-item-justify-right {
  justify-content: right;
}

.dx-drawer-wrapper,
.header-component {
  position: relative;
  z-index: 100;
}

.img-thumbnail {
  width: 100%;
  height: 100%;
}

.positioned-absolute-centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

//Image for drag and drop
#dropzone-external {
  width: auto;
  height: 30px;
  background-color: rgba(255, 255, 255, 0.1);
  border-width: 2px;
  border-style: dashed;
  padding: 10px;
}

.widget-container image {
  margin-right: 54px !important;
}

.widget-container > span {
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 16px;
}

#dropzone-image {
  max-width: 100%;
  max-height: 100%;
}

#dropzone-text > span {
  font-weight: 100;
  opacity: 0.5;
}

.drag-and-drop-image-flex-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.dx-fileuploader-show-file-list .dx-fileuploader-files-container {
  padding-top: 0px;
}

//-----------Image drag & drop End

.custom-hr {
  border: none;
  height: 1px;
  background-color: #d1d1d1;
  margin-bottom: 20px;
}

.custom-hr2 {
  border: none;
  height: 1px;
  background-color: #d1d1d1;
  padding: 0px 20px;
  margin: 0px 15px 20px 10px;
}

.custom-hr3 {
  border: none;
  height: 1px;
  background-color: #d1d1d1;
  margin-bottom: 20px;
}

.transparent-bg {
  background: none !important;
  /* Remove background */
  box-shadow: none !important;
  /* Remove the box shadow */
}

.swal2-loader {
  border-color: #ffffff;
  /* Outer color */
  border-top-color: transparent;
  border-bottom-color: transparent;
  /* Inner color (make it transparent for the rotating effect) */
}

.default-option-label {
  margin-left: 60px;
  font-style: italic;
  padding: 5px 6px;
  border-radius: 10%;
  background-color: #74b973;
  color: white;
  font-size: 10px;
}

/* Disable pointer cursor for the entire DropDownBox */
.custom-dropdown .dx-dropdowneditor.dx-dropdowneditor-field-clickable,
.dx-dropdowneditor.dx-dropdowneditor-field-clickable .dx-texteditor-input {
  cursor: text;
  /* Prevent the cursor from changing to a pointer */
}

//Contra Styling
.border-bottom {
  .popup-form-offset-section-title {
    font-size: 18px;
    font-weight: 600;
  }

  .popup-form-toolbar-section .popup-form-toolbar-section-content {
    font-size: 14px;
    font-weight: 400;
  }
}

.dx-datagrid-rowsview .dx-row-focused.dx-data-row > td:not(.dx-focused) {
  background-color: #f8f8f8;
  color: #333;
}

.dx-datagrid-rowsview .dx-row-focused.dx-data-row .dx-command-edit:not(.dx-focused) .dx-link {
  background-color: #f8f8f8;
}

.dx-datagrid-rowsview .dx-row-focused.dx-data-row > td {
  border-bottom: 1px solid #ddd;
}

.dx-widget.dx-button.dx-button-mode-contained.dx-button-normal.dx-button-has-icon[aria-label="eye-regular"] {
  border-style: none;
}

////////////Default styling End

//Navigation - Start
.side-nav-outer-toolbar .dx-drawer {
  height: 100%;
}

.dx-card.wide-card {
  border-radius: 0;
  margin-left: 0;
  margin-right: 0;
  border-right: 0;
  border-left: 0;
}

.with-footer > .dx-scrollable-wrapper > .dx-scrollable-container > .dx-scrollable-content {
  height: 100%;

  & > .dx-scrollview-content {
    display: fixed;
    flex-direction: column;
    min-height: 100%;
  }
}

$side-panel-min-width: 60px;

////////////////////Side Navigation End

/// //button styling - Start
.group-item-button-item {
  padding-right: 5px !important;
  padding-left: 0px !important;
}

.buttons-container {
  margin-right: 0;
  margin-left: auto;
}

.column-chooser-button,
.listing-page-add-btn,
.listing-page-refresh-btn {
  margin-left: 5px;
}

//column chooser button & refresh button
.column-chooser-button,
.listing-page-refresh-btn {
  background: #efefef;
  border: none;
}

// listing page add button
.listing-page-add-btn {
  position: relative;
  color: black;
}

.customized-listing-add-btn {
  position: relative;
  background-color: #46cf64;

  .dx-button-content,
  .dx-icon.dx-icon-add,
  .dx-icon.dx-icon-spindown {
    color: white;
  }
}

.yesno-btn {
  margin: 0px 5px;
  font-size: 16px;
  color: white;
  padding: 5px;
  width: 50px;
  border-radius: 10px;
  border: none;
  cursor: pointer;
}

.yesno-btn:hover {
  opacity: 0.6;
}

.yesno-confirm-btn {
  background-color: #f44336;
}

.yesno-cancel-btn {
  background-color: #8bc34a;
}

.customized-listing-add-btn.dx-widget.dx-dropdownbutton.dx-dropdownbutton-has-arrow {
  align-self: last baseline;
  position: relative;
  border-radius: 4px;
}

#offset-check-btn {
  margin-top: 10px;

  .dx-checkbox-icon {
    width: 16px;
    height: 16px;
  }

  .dx-checkbox-icon::before {
    font-size: 13px;
    padding-bottom: 2px;
  }
}

.dx-widget.dx-checkbox.popup-header-cancel-input[aria-checked="false"] {
  height: 16px !important;
  width: 16px !important;
}

.dx-checkbox-container .dx-checkbox-icon {
  width: 16px;
  height: 16px;
  font-size: 14px;
}

.dx-widget.dx-button.dx-button-mode-contained.dx-button-normal.dx-button-has-text.dx-button-has-icon.user-listing-btn {
  background-color: #46cf64;
  color: white;

  .dx-icon.dx-icon-plus {
    color: white;
  }
}

//listing page add button, focused & hover state
.dx-widget.dx-button.dx-button-mode-contained.dx-button-normal.dx-button-has-text.dx-button-has-icon.listing-page-add-btn.dx-state-focused,
.customized-listing-add-btn.dx-state-focused {
  background-color: #46cf64;
}

.dx-widget.dx-button.dx-button-mode-contained.dx-button-normal.dx-button-has-text.dx-button-has-icon.listing-page-add-btn.dx-state-hover,
.customized-listing-add-btn.dx-state-hover {
  background-color: #28a745 !important;
  opacity: 1 !important;
}

.dx-command-edit.dx-command-edit-with-icons a[title="E-Invoice"] {
  color: #46cf64;
}

.customized-popup-form-footer {
  float: right;
  margin-bottom: 15px;
  margin-right: 15px;
}

.dx-command-edit.dx-command-edit-with-icons {
  .dx-link.dx-link-delete.dx-icon-trash.dx-link-icon,
  .dx-link.dx-link-edit.dx-icon-edit.dx-link-icon,
  .dx-link.icon-color.dx-icon-arrowdown.dx-link-icon {
    color: #46cf64;
  }
}

.setting-edit-btn,
.setting-ctn-btn {
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  font-family: "Inter", serif;
  font-size: 14px;
  font-weight: bold;
}

.setting-edit-btn {
  background-color: #ffffff;
  margin-right: 15px;
  color: #46cf64;
  border: 1px solid #32c682;
  width: 90px;
}

.setting-ctn-btn {
  background-color: #46cf64;
  color: #fff;
  width: 95px;
}

.custom-dropdown {
  /* Target the dropdown button */
  .dx-dropdowneditor-button {
    border-radius: 0 8px 8px 0;
    background-color: #fff;
    /* Ensure the background matches */
  }

  /* Ensure the input field maintains its own style */
  .dx-texteditor-input {
    border-radius: 8px 0 0 8px;
    /* Rounded left corners */
  }

  /* Optional: Hover effect for the dropdown button */
  .dx-dropdowneditor-button:hover {
    background-color: #f0f0f0;
    border-left-color: #007bff;
    /* Highlight border on hover */
  }

  /* Optional: Focus effect */
  .dx-dropdowneditor-button:focus {
    outline: none;
    border-left-color: #0056b3;
  }
}

.dx-widget.dx-button.dx-button-mode-outlined.dx-button-success.dx-button-has-text {
  background-color: #fff;
}

///////////////button styling End

//toolbar styling - Start
.datagrid-toolbar-section {
  padding-bottom: 10px !important;
}

////////////////toolbar styling End

//Listing styling - Start
.datagrid-customized-title {
  font-size: 25px;
  font-weight: bold;
}

.dx-datagrid.dx-gridbase-container {
  padding-top: 10px;

  .dx-widget.dx-datagrid-pager.dx-pager {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.listing-page-datagrid {
  margin-bottom: 20px;

  .dx-link-edit.dx-icon-edit,
  .dx-link-delete.dx-icon-trash {
    color: #46cf64;
  }

  .dx-row.dx-state-hover .dx-command-edit-with-icons {
    color: #3fba59 !important;
  }

  .dx-datagrid-rowsview .dx-data-row {
    border-top: 1px solid #eaecf0;
    border-bottom: none;
  }

  .dx-datagrid-headers .dx-header-row {
    padding: 10px;
  }
}

tbody tr td[role="gridcell"][aria-colindex="9"] {
  border-left-style: none !important;
}

.dx-pointer-events-none.dx-first-cell {
  border-right-style: none !important;
}

tbody tr td[role="columnheader"],
.dx-command-edit.dx-command-edit-with-icons.dx-cell-focus-disabled.dx-datagrid-drag-action,
.dx-command-select.dx-cell-focus-disabled.dx-editor-cell.dx-editor-inline-block {
  background-color: #efefef;
}

.dx-datagrid-action.dx-cell-focus-disabled.dx-datagrid-drag-action {
  border-left: solid 1px #ddd;
  border-right: solid 1px #ddd;
}

//normal-listing - Start
#normal-listing {
  .dx-datagrid-header-panel {
    border-bottom: none;
  }

  .listing-page-datagrid {
    .dx-datagrid-headers .dx-datagrid-table {
      border-top-style: none !important;
    }

    .dx-datagrid-rowsview .dx-data-row .dx-editor-cell {
      padding: 1px !important;
    }

    .dx-datagrid-headers,
    .dx-datagrid-headers .dx-header-row > td {
      border-bottom: none !important;
    }
  }

  .dx-row.dx-column-lines.dx-header-row {
    padding-top: 0px;
    padding-bottom: 0px;

    //listing header
    td[role="columnheader"] .dx-datagrid-text-content {
      font-size: 15px;
      color: rgb(95, 95, 95);
      font-weight: 500;
    }
  }

  //header bottom line
  .dx-datagrid-content .dx-datagrid-table .dx-row.dx-data-row.dx-column-lines[aria-rowindex="1"] {
    border-top-color: #d7d7d7;
  }

  //listing
  .listing-page-datagrid.listing-datagrid.dx-widget.dx-visibility-change-handler {
    padding-top: 5px;
  }

  .dx-pointer-events-none.dx-first-cell.dx-cell-focus-disabled {
    border-right-style: none !important;
  }

  .dx-datagrid-scrollable-simulated.dx-datagrid-content.dx-datagrid-scroll-container
    .dx-datagrid-table.dx-datagrid-table-fixed
    .dx-row.dx-column-lines.dx-header-row
    td[role="columnheader"] {
    border-top-style: none;
  }
}

///----normal-listing End

.dx-datagrid-nodata {
  white-space: pre-line;
}

.dx-datagrid-rowsview .dx-data-row .dx-cell-modified.dx-cell-modified::after {
  border: none !important;
}

.clickable-span-tag {
  color: rgb(0, 110, 255);
  cursor: pointer;
}

.clickable-span-tag:hover {
  text-decoration: underline;
}

.highlight-span-tag {
  color: rgb(0, 110, 255);
}

//breadcrumb
.content-block.dx-card.responsive-paddings .listing-page-title-container {
  padding-bottom: 15px;
  padding-top: 15px;
}

.dx-command-expand.dx-datagrid-group-space.dx-cell-focus-disabled {
  background-color: #efefef;
}

.dx-row.dx-footer-row {
  background-color: #efefef;
}

////////////////Listing styling End

//date box styling - Start
.listing-date-from-container,
.listing-date-to-container {
  margin-right: 5px;

  .dx-texteditor-container {
    background-color: #efefef;
    border-radius: 4px;

    .dx-placeholder {
      color: rgb(95, 95, 95);
      font-weight: 500;
    }
  }

  .dx-show-invalid-badge.dx-datebox.dx-textbox.dx-texteditor.dx-show-clear-button.dx-dropdowneditor-button-visible.dx-editor-outlined.dx-texteditor-empty.dx-widget.dx-visibility-change-handler.dx-auto-width.dx-dropdowneditor.dx-datebox-date.dx-datebox-calendar {
    border-style: none;
  }
}

///////////////date box styling End

//search panel styling - Start
.listing-page-search-box.dx-show-invalid-badge.dx-textbox.dx-texteditor.dx-editor-outlined.dx-texteditor-empty.dx-widget {
  background-color: #efefef;
  border-style: none;
  border-radius: 5px;
  font-size: 15px;
  margin-right: 5px;

  .dx-placeholder {
    color: rgb(95, 95, 95);
    font-weight: 500;
  }
}

/////////////search panel styling End

//Path Indicator styling - Start
.parent-segment {
  color: #6c6a60 !important;
}

//////////////Path Indicator styling End

//Treelist styling - Start
.listing-page-treelist {
  margin-bottom: 0px;
}

.dx-scrollable-content .content-block {
  padding-bottom: 10px;
}

.specialTreeList .dx-treelist-container .dx-treelist-headers .dx-treelist-table tbody tr td {
  text-align: center !important;
}

.specialValue {
  text-align: right !important;
}

//////////////Treelist styling End

//Text editor styling - Start
.dx-texteditor-container .dx-texteditor-input-container .dx-placeholder {
  font-size: 15px;
}

//////////////Text editor styling End

//Popup form styling - Start
.popup-form-item-container1 {
  display: grid;
  grid-template-columns: 100%;
}

.popup-form-item-container2 {
  display: grid;
  grid-template-columns: 50% 50%;
}

.popup-form-item-container3 {
  display: grid;
  grid-template-columns: 33.33% 33.33% 33.33%;
}

.popup-form-item-container4 {
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
}

.popup-form-item-container5 {
  display: grid;
  grid-template-columns: 66.67% 33.33%;
}

.popup-form-item-container6 {
  display: grid;
  grid-template-columns: 33.33% 66.67%;
}

.popup-form-item-container7 {
  display: grid;
  grid-template-columns: 75% 25%;
}

.popup-group-form-item {
  display: flex;
  margin: 2.5px 10px;
  align-items: flex-end;
}

.popup-group-form-item-block {
  display: block;
  margin: 2.5px 10px;
}

.popup-group-form-item.group-form-item-align-top {
  align-items: flex-start;
}

.group-form-item-align-bottom {
  align-items: flex-end;
}

.align-top-item-label {
  padding-top: 10px;
}

.popup-group-form-label {
  padding-bottom: 10px;
  width: 150px;
}

.popup-group-form-input {
  width: calc(100% - 150px);
}

.popup-group-form-btn {
  margin-right: 5px !important;
}

.popup-group-form-container {
  padding: -20px !important;
}

.popup-from-group-container {
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
}

.popup-form-title {
  padding: 10px 40px;
  font-size: 30px;
  font-weight: bold;
}

.popup-group-item-container-flex {
  display: block;
}

.customized-popup-form .dx-popup-content {
  height: 100% !important;
  padding: 0 !important;
}

.popup-form-title-grid {
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
}

.popup-form-close-container {
  text-align: right;
}

.popup-form-header-cancel {
  font-size: 15px;
  font-weight: normal;

  .popup-header-cancel-text {
    margin-left: 10px;
    margin-right: 20px;
    position: relative;
    bottom: 4px;
  }

  .popup-header-cancel-input {
    position: relative;
    bottom: 6px;
    width: 10px;
  }
}

.popup-form-header-view-history {
  .dx-button-text {
    font-size: 16px;
  }
}

@media screen and (min-width: 300px) {
  .popup-form-item-container2 {
    grid-template-columns: auto;
  }

  .popup-form-item-container3 {
    grid-template-columns: auto;
  }

  .popup-form-item-container4 {
    grid-template-columns: auto;
  }

  .popup-form-item-container7 {
    grid-template-columns: auto;
  }
}

@media screen and (min-width: 800px) {
  .popup-form-item-container2 {
    grid-template-columns: 50% 50%;
  }

  .popup-form-item-container3 {
    grid-template-columns: 50% 50%;
  }

  .popup-form-item-container4 {
    grid-template-columns: 50% 50%;
  }

  .popup-group-item-container-flex {
    display: flex;
  }
}

@media screen and (min-width: 1200px) {
  .popup-form-item-container3 {
    grid-template-columns: 33.33% 33.33% 33.33%;
  }

  .popup-form-item-container4 {
    grid-template-columns: 25% 25% 25% 25%;
  }

  .popup-form-item-container7 {
    display: grid;
    grid-template-columns: 75% 25%;
  }
}

@media screen and (max-width: 1200px) {
  .popup-form-item-container5 {
    grid-template-columns: auto;
  }

  .popup-form-item-container6 {
    grid-template-columns: auto;
  }
}

.popup-form-submit-btn {
  margin-right: 5px;

  .dx-button-text,
  .dx-icon-spindown {
    color: white !important;
  }
}

.popup-form-toolbar-section {
  padding-left: 20px !important;
}

.popup-form-offset-section-title {
  font-size: 18px;
  font-weight: 600;
}

.popup-form-toolbar-description {
  width: 400px !important;
}

.next-doc-display {
  color: #868686;
  font-size: 12px;
  padding-left: 5px;
}

.next-doc-label {
  padding-top: 15px;
}

.custom-form-grid-section1 {
  display: grid;
  grid-template-columns: 100%;
  border-bottom: none !important;
}

.custom-form-grid-section2,
.custom-form-grid-section3,
.custom-form-grid-section4 {
  display: grid;
  grid-template-columns: 100% !important;
}

.more-description-with-data .dx-button-content .dx-icon {
  color: #46cf64;
}

.children-datagrid-container {
  margin: 0 20px;
}

// popupform content padding
.dx-validationgroup {
  padding-bottom: 15px;
}

//////////////Popup form styling End

//Lookup styling - Start

.lookup-datagrid .dx-row {
  cursor: pointer;
}

.grid-children-lookup-select {
  padding-left: 10px;
}

.customized-lookup-search-container {
  display: flex;
  margin-bottom: 5px;
}

.customized-lookup-close-btn {
  float: right;
}

.customized-lookup-btn-section {
  margin-left: 5px;
}

////////////Lookup styling End

//Login & Register - Start
.auth-popup-content {
  background-color: #fff;
  border-radius: 8px;
  text-align: center;
  max-width: 800px;
  padding: 20px 50px;
  min-height: 100%;
  display: flex;
  /* Use flexbox */
  flex-direction: column;
  /* Arrange children in a column */
  justify-content: center;
  /* Center content vertically */
}

.auth-popup-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 1rem;
}

.auth-popup-subtitle {
  font-size: 16px;
  margin-bottom: 1.5rem;
  font-weight: 480;
}

.auth-buttons {
  margin-top: 0px;
}

.auth-button {
  display: block;
  width: 100%;
  height: 50px;
  border-radius: 5px;
  outline: none;
  border: none;
  background-color: #38a169;
  background-size: 200%;
  font-size: 1.2rem;
  color: #fff;
  font-family: "Poppins", sans-serif !important;
  margin: 1rem 0;
  margin-top: 1.5em;
  cursor: pointer;
  transition: 0.5s;
}

.auth-button:hover {
  background-color: #32be8f;
}

.auth-link {
  display: block;
  margin-top: 1rem;
  font-size: 20px;
  color: #38a169 !important;
  text-decoration: none;
  font-family: "Poppins", sans-serif !important;
}

.auth-pop-up-input {
  padding: 0.5rem;
}

.icon-flex {
  display: flex;
  align-items: center;
  justify-content: center;
}

.auth-link-inline {
  display: inline;
  font-size: 16px;
  color: #38a169 !important;
  text-decoration: none;
}

.icon-container {
  background-color: #38a169;
  border-radius: 50%;
  padding: 1rem;
  margin-bottom: 1.5rem;

  i {
    font-size: 2rem;
    color: #fff;
  }
}

.auth-buttons-container {
  display: flex;
  justify-content: flex-end;
  /* Align items to the right */
  gap: 1rem;
  padding-bottom: 15px;

  .auth-background-green-button {
    background-color: #38a169;
    color: #fff;
    border: none;
    font-size: 16px;
    padding: 0.5rem 2rem;
    border-radius: 5px;
    cursor: pointer;
  }

  .auth-background-white-button {
    font-size: 16px;
    padding: 0.5rem 2rem;
    border-radius: 5px;
    cursor: pointer;
    background-color: #fff;
    color: #38a169;
    border: 2px solid #38a169;
  }

  .auth-background-white-button:hover {
    background-color: #f9f9f9;
  }

  .got-it-button {
    flex: 1;
    font-size: 16px;
    padding: 0.85rem 2rem;
    border-radius: 5px;
    cursor: pointer;
    background-color: #38a169;
    color: #fff;
    border: none;
  }

  .send-again-button {
    font-size: 16px;
    padding: 0.5rem 1rem;
    border-radius: 5px;
    cursor: pointer;
    background-color: #fff;
    color: #38a169;
    border: 2px solid #38a169;
  }

  .send-again-button:hover {
    background-color: #f9f9f9;
  }
}

.reset-password-send {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  background-color: #fff;
  border-radius: 8px;
  margin: 0 auto;
  text-align: center;
  min-height: 100%;
}

.loading-indicator-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  margin-top: 20px;
  margin-bottom: 40px;
}

.otp-field {
  display: flex;
  justify-content: center;
  align-items: center;

  input {
    width: 60px;
    font-size: 32px;
    padding: 10px;
    text-align: center;
    border-radius: 5px;
    margin: 2px;
    border: 2px solid #38a169;
    font-family: "Poppins", sans-serif !important;
    font-weight: bold;
    color: #525252;
    outline: none;
    transition: all 0.1s;
  }

  input:focus {
    border: 2px solid #439167;
    box-shadow: 0 0 2px 1.5px #439167;
  }
}

.disabled {
  opacity: 0.5;
}

.space {
  margin-right: 1rem !important;
}

.loader {
  width: 48px;
  height: 48px;
  border: 5px solid #fff;
  border-bottom-color: #337ab7;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

.reset-password-invite-container {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  margin-top: 4rem;
  height: 70vh;
  position: relative;

  .auth-popup-content {
    background-color: #ffffff;
    width: 900px;
    padding: 20px 120px;
    box-shadow: 0 0 40px rgba(211, 211, 211, 0.8);
  }
}

///////////////Login & Register End

//Switch Company & Manage User - Start

.remaining-seat-section {
  display: flex;
  gap: 20px;
  margin: 20px 0;
}

.total-seat,
.seat-in-use,
.inactive-seat,
.seat-remaining {
  padding: 10px 20px;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 500;
  background-color: #f5f5f5;
}

.inactive-seat {
  color: #ff5555;
  /* Orange color for seat in use */
}

.seat-in-use {
  color: #e67e22;
  /* Orange color for seat in use */
}

.seat-remaining {
  color: #28a745;
  /* Green color for seat remaining */
}

.locked-user-row {
  position: relative;
  background-color: rgba(255, 255, 255, 0.9);
  /* Increased transparency */
  z-index: 1;
  /* Ensure the row stays behind fixed elements */
}

.locked-user-row::after {
  content: "User Locked Due to Seat Limitation";
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  text-align: center;
  font-size: 16px;
  color: #6f6f6f;
  font-weight: bold;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 0;
  pointer-events: none;
}

.company-selection-popup-content {
  padding: 15px;
  font-family: "Open Sans", sans-serif;
  overflow: auto;
  /* Enables scrolling for overflow content */
}

.company-selection-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;

  h2 {
    margin: 0;
    font-weight: 600;
  }
}

.company-selection-company h3 {
  font-size: 18px;
  font-weight: 550;
  margin: 0 0 10px;
}

.company-selection-new-btn {
  background-color: #46cf64;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 5px 20px;
  cursor: pointer;
  font-family: "Inter", serif;
  font-size: 15px;

  .plus-sign {
    font-size: 20px;
  }
}

.manage-user-company-selection-container {
  height: 450px;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
}

/* Custom scrollbar styles */
.manage-user-company-selection-container::-webkit-scrollbar {
  width: 8px;
  /* Width of the scrollbar */
}

.manage-user-company-selection-container:-webkit-scrollbar-track {
  background: #f1f1f1;
  /* Background of the scrollbar track */
  border-radius: 10px;
  /* Round the scrollbar track */
}

.manage-user-company-selection-container::-webkit-scrollbar-thumb {
  background: #888;
  /* Background color of the scrollbar thumb */
  border-radius: 10px;
  /* Round the scrollbar thumb */
}

.manage-user-company-selection-container::-webkit-scrollbar-thumb:hover {
  background: #555;
  /* Background color when hovering over the scrollbar thumb */
}

.company-selection-details {
  border: 1px solid #dbdbdb;
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 10px;
  position: relative;

  .company-selection-name {
    font-weight: 600;
    margin-bottom: 10px;
    font-size: 17px;
    color: #808080;
  }

  .company-selection-status {
    font-size: 12px;
    padding: 2px 5px;
    border-radius: 15px;
    margin-left: 10px;
  }

  .company-selection-status.active {
    background-color: #fff;
    border: 1px solid #46cf64;
    font-weight: 600;
    color: #46cf64;
  }

  .company-selection-status.inactive {
    background-color: #fff;
    border: 1px solid #9f9f9f;
    font-weight: 600;
    color: #9f9f9f;
  }

  .company-selection-current-using {
    font-size: 12px;
    background-color: #e7efff;
    border-radius: 15px;
    padding: 4px 10px;
    color: #4482ff;
    font-weight: 600;
  }
}

.company-selection-name {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
}

.company-selection-status-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.company-selection-details.selected {
  border: 2px solid #32c682;

  .company-selection-name,
  .company-selection-invited-by {
    color: #000000;
    /* Default color when selected */
  }
}

.company-selection-details.current-used {
  border: 2px solid green;
  /* Green border for currently used company */
}

.company-selection-details.active {
  .company-selection-name,
  .company-selection-invited-by {
    color: #000000;
    /* Black color for active state */
  }
}

.company-selection-buttons {
  margin-top: 30px;
  text-align: right;
}

.company-selection-cancel-btn,
.company-selection-continue-btn {
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  font-family: "Inter", serif;
  font-size: 14px;
}

.company-selection-cancel-btn {
  background-color: #ffffff;
  margin-right: 15px;
  color: #46cf64;
  border: 1px solid #32c682;
}

.company-selection-continue-btn {
  background-color: #46cf64;
  color: #fff;
}

.company-selection-info {
  display: grid;
  grid-template-columns: 150px 1fr;
  margin-bottom: 5px;
}

.company-selection-label {
  margin-right: 10px;
}

.company-selection-value {
  text-align: left;
  font-weight: 600;
}

.invited-email {
  font-weight: 600;
  /* Bold font weight for email addresses */
}

.manage-user-header {
  h2 {
    margin-bottom: 12px;
    font-weight: 600;
  }

  p {
    margin-bottom: 12px;
    color: #686868;
  }
}

.manage-user-selection-buttons {
  margin-top: 30px;
  text-align: right;
  margin-bottom: 12px;
}

.manage-user-selection-cancel-btn,
.manage-user-selection-continue-btn {
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  font-family: "Inter", serif;
  font-size: 14px;
}

.manage-user-selection-cancel-btn {
  background-color: #ffffff;
  margin-right: 15px;
  color: #46cf64;
  border: 1px solid #32c682;
}

.manage-user-selection-continue-btn {
  background-color: #46cf64;
  color: #fff;
}

.manage-user-selection-email {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
  margin-right: 50px;
}

///////////////Switch Company & Manage User End

//Subscription Plan - Start
.popup-subscription-plan-container {
  text-align: left;
  margin: 0;
  padding: 0;
  width: 100%;
  padding-left: 30px;
}

.popup-subscription-total-text {
  font-weight: bold;
  font-size: 19px;
}

///////////////Subscription Plan End

//E-invoice -Start
.e-invoice-module-form-title-bg {
  color: #46cf64;
  border-bottom: 2px solid #46cf64;

  .popup-form-close-btn {
    .dx-icon.dx-icon-close {
      color: #838383;
    }
  }

  .popup-header-cancel-text {
    color: #838383;
  }
}

.e-invoice-module-parent-section {
  background-color: #efefef;
  padding: 10px;
  margin: 10px 30px;
  margin-bottom: 30px;
  border-radius: 10px;
}

.e-invoice-grid-section {
  margin: 0 30px;
}

.e-invoice-module-total-section {
  padding: 10px;
  margin: 20px;
}

.e-invoice-module-children-datagrid {
  .dx-datagrid-headers {
    background-color: #efefef !important;
    color: black;
    border-top: none;
  }

  .dx-datagrid-action {
    border-color: #d0cece !important;
  }

  .dx-datagrid-total-footer {
    background-color: #efefef !important;
    border: none !important;
  }

  .dx-toolbar {
    padding-top: 5px;
    padding-right: 15px;
    padding-bottom: 15px !important;
    margin-bottom: 0px !important;
  }

  .dx-datagrid-header-panel {
    background-color: #efefef;
  }

  .dx-datagrid-rowsview .dx-scrollable-content {
    padding-bottom: 5px;
  }
}

/////////////////////E-invoice End

//////////////////////////////Listing & Popup CSS
.listing-btn {
  background-color: #46cf64;
  align-self: last baseline;
  position: relative;
  color: white !important;

  .dx-icon {
    color: white;
    padding-right: 10px;
  }

  .dx-button-text {
    color: white;
  }
}

.listing-datagrid {
  padding-top: 15px;

  .dx-datagrid-headers {
    color: black;
  }

  .dx-datagrid-table.dx-datagrid-table-fixed
    .dx-row.dx-column-lines.dx-header-row
    td[role="columnheader"] {
    font-size: 14px;
    color: black;
    font-weight: 400;
    border-bottom-style: none;
  }

  .dx-command-edit.dx-command-edit-with-icons {
    border-left-style: none;

    .dx-link.dx-link-edit.dx-icon-edit.dx-link-icon,
    .dx-link.dx-link-delete.dx-icon-trash.dx-link-icon {
      color: #46cf64 !important;
    }
  }

  td[role="columnheader"],
  .dx-command-edit.dx-command-edit-with-icons.dx-cell-focus-disabled.dx-datagrid-drag-action {
    font-size: 15px;
    font-weight: 500;
    color: #5f5f5f;
    border-bottom-style: none !important;
  }

  .dx-datagrid-rowsview.dx-scrollable-simulated {
    border-top-style: none;
  }
}

.border-bottom,
.children-datagrid,
.popup-form-grid-container {
  margin: 0 20px;
  padding: 10px;

  .dx-datagrid-headers {
    background-color: #efefef !important;
    color: black;
    // border-bottom: 2px solid #46CF64 !important;
  }

  .dx-datagrid-action {
    border-color: #ddd !important;
  }

  .dx-datagrid-total-footer {
    background-color: #efefef !important;
    // border: none;
  }

  .dx-toolbar {
    padding-top: 5px;
    padding-bottom: 10px;
    margin-bottom: 0px !important;
  }

  .dx-item-content.dx-toolbar-item-content {
    font-size: 14px;
    font-weight: 400;
  }

  // .dx-datagrid-header-panel {
  //     background-color: #EFEFEF;
  // }

  .dx-datagrid-total-footer > .dx-datagrid-content {
    padding-top: 0;
    padding-bottom: 0;
  }

  .dx-datagrid-rowsview .dx-scrollable-content {
    color: black;
    padding-bottom: 0px;
  }

  .dx-datagrid-rowsview.dx-datagrid-nowrap.dx-scrollable.dx-visibility-change-handler.dx-scrollable-both.dx-scrollable-native.dx-scrollable-native-generic {
    // .dx-datagrid-rowsview.dx-datagrid-nowrap.dx-scrollable.dx-visibility-change-handler.dx-scrollable-both.dx-scrollable-simulated {
    border-top-style: none;
  }

  .dx-datagrid-scrollable-simulated.dx-datagrid-content.dx-datagrid-scroll-container
    .dx-datagrid-table.dx-datagrid-table-fixed
    tbody
    tr
    td[role="columnheader"] {
    border-bottom-style: none;
  }

  .dx-datagrid-total-footer .dx-datagrid-scrollable-simulated {
    padding: 0;
  }
}

.form-title-bg {
  color: #46cf64;
  border-bottom: 2px solid #46cf64;

  .popup-form-close-btn {
    .dx-icon.dx-icon-close {
      color: #838383;
    }
  }

  .popup-header-cancel-text {
    color: #838383;
  }
}

.form-subtitle-bg {
  color: #6f6f6f;
  font-size: 20px;
}

.form-sub-subtitle-bg {
  color: #6f6f6f;
  font-size: 17px;
  margin-bottom: 12px;
}

.group-name-bg {
  background-color: #46cf64 !important;
  border-bottom: 3px solid #46cf64;
  color: #000000;
}

.maintenance-form-title-bg {
  color: #46cf64;

  .popup-form-close-btn {
    .dx-icon.dx-icon-close {
      color: #838383;
    }
  }

  .popup-header-cancel-text {
    color: #838383;
  }
}

.gray-background {
  background-color: #efefef;
  border-radius: 10px;
  margin: 20px 20px 30px;
  padding: 10px;
}

.border-bottom.custom-form-grid-section1 {
  background-color: #efefef;
  border-radius: 10px;
  margin: 20px;
  padding: 10.1px;
}

.dx-datagrid.dx-gridbase-container.dx-datagrid-borders .dx-datagrid-headers.dx-datagrid-nowrap {
  border-bottom-style: none;
  color: black;
}

.dx-datagrid-scrollable-simulated.dx-datagrid-content.dx-datagrid-scroll-container,
.dx-widget.dx-visibility-change-handler
  .dx-datagrid.dx-gridbase-container.dx-datagrid-borders
  .dx-datagrid-scrollable-simulated.dx-datagrid-content.dx-datagrid-scroll-container {
  padding-top: 0px;
  padding-bottom: 0px;
}

.dx-datagrid-rowsview.dx-datagrid-nowrap.dx-fixed-columns.dx-scrollable.dx-visibility-change-handler.dx-scrollable-both.dx-scrollable-simulated {
  // .dx-datagrid-rowsview.dx-datagrid-nowrap.dx-empty.dx-scrollable.dx-visibility-change-handler.dx-scrollable-both.dx-scrollable-simulated,
  // .dx-datagrid-rowsview.dx-datagrid-nowrap.dx-scrollable.dx-visibility-change-handler.dx-scrollable-both.dx-scrollable-simulated {
  border-top-style: none;
}

.border {
  border: 2px solid #46cf64;
}

.border-bottom-none {
  border: 2px solid #46cf64;
  border-bottom: none;
}

.popup-form-group-name {
  background-color: #ffffff;
  padding: 10px 10px;
  font-weight: 500;
  font-size: 20px;
  color: #46cf64;
  margin-bottom: 1px;
}

.parent-section {
  background-color: #efefef;
  padding: 10px;
  margin: 20px;
  margin-bottom: 30px;
  border-radius: 10px;
}

.total-section {
  padding: 10px;
  margin: 0 20px;
}

.transform-container {
  padding: 20px;
}

.children-datagrid-container.children-datagrid {
  .dx-widget.dx-visibility-change-handler
    .dx-datagrid.dx-gridbase-container.dx-datagrid-borders
    .dx-datagrid-headers.dx-datagrid-nowrap {
    border-bottom-style: none;
  }

  .dx-datagrid-headers.dx-datagrid-nowrap {
    border-top: solid 1px #ddd;
  }

  .dx-datagrid-rowsview.dx-datagrid-nowrap.dx-scrollable.dx-visibility-change-handler.dx-scrollable-both.dx-scrollable-native.dx-scrollable-native-generic {
    border-top-style: none;
  }
}

.popup-form-title.form-title-bg.popup-form-title {
  border-bottom: 2px solid #46cf64;

  .popup-form-title-grid {
    color: #46cf64;
  }
}

//change the classname for gl module to the one shared with all module then delete (popup form title)
.popup-form-title.theme-color.title-font-size {
  border-bottom: 2px solid #46cf64;
  color: #46cf64;

  .popup-form-title-grid .popup-form-close-container .popup-form-header-cancel {
    color: #838383;
  }
}

.dx-datagrid.dx-gridbase-container.dx-datagrid-borders
  .dx-datagrid-scrollable-simulated.dx-datagrid-content.dx-datagrid-scroll-container
  .dx-datagrid-table.dx-datagrid-table-fixed {
  color: black;
}

//Listing Edit btn styling
#listing-edit-btn {
  margin-right: 10px;
  border-color: #46cf64;
  color: #46cf64;
  background-color: white;
}

.dx-datagrid-rowsview.dx-datagrid-nowrap.dx-scrollable.dx-visibility-change-handler.dx-scrollable-both.dx-scrollable-native.dx-scrollable-native-generic.dx-scrollable-disabled {
  border-bottom-style: solid;
}

.item-align-bottom {
  align-items: flex-end;
}

.errorMessage {
  margin-left: 10px;
  color: red;
  align-items: center;
  margin-top: -27.5px;
}

.outstanding-amount-color .dx-texteditor-input {
  color: red;
  font-weight: bolder;
}

.dx-widget.dx-button.dx-button-mode-contained.dx-button-success.dx-button-has-text[aria-label="Edit"] {
  background-color: white;
  border-color: #46cf64;
  color: #46cf64;
}

.popup-form-hr {
  background-color: #46cf64;
  margin: 0px 10px 5px 10px;
  height: 1px;
  border: none;
}

.maintenance-popup-form-hr {
  background-color: #46cf64;
  margin: 0px 30px 5px 30px;
  height: 1px;
  border: none;
}

.popup-group-form-item {
  display: flex;
  margin: 2.5px 10px;
  align-items: flex-end;
}

.dx-checkbox.dx-state-readonly .dx-checkbox-icon {
  border-color: #bdbdbd;
  background-color: #fff;
}

button {
  border: none;
}

.dx-icon-custom-styleOutstanding {
  font-size: 28.5px !important;
}

.dx-treelist-rowsview.dx-treelist-nowrap.dx-scrollable.dx-visibility-change-handler.dx-scrollable-both.dx-scrollable-simulated {
  border-top-style: none;
}

//edit button
.dx-widget.dx-button.dx-button-mode-text.dx-button-normal.dx-button-has-icon.listing-customized-btn {
  text-align: right;
}

.dx-treelist-text-content.dx-text-content-alignment-left,
.dx-treelist-text-content.dx-text-content-alignment-right.dx-sort-indicator,
.dx-treelist-text-content.dx-text-content-alignment-right {
  font-size: 15px;
  color: rgb(95, 95, 95);
  font-weight: 500;
}

.dx-widget.dx-button.dx-button-mode-contained.dx-button-success.dx-button-has-text {
  background-color: #46cf64;
}

.dx-widget.dx-button.dx-button-mode-outlined.dx-button-success.dx-button-has-text {
  border-color: #46cf64;
  color: #46cf64;
}

.listing-datebox-container > div {
  align-items: center;
}

.dx-treelist-rowsview.dx-treelist-nowrap.dx-scrollable.dx-visibility-change-handler.dx-scrollable-both.dx-scrollable-native.dx-scrollable-native-generic {
  font-size: small;
}

.dx-row.dx-footer-row .dx-command-edit.dx-command-edit-with-icons {
  border-left-style: none !important;
}

.border-bottom.custom-section-1 {
  padding: 10px;
  margin: 20px;
  background-color: #efefef;
  border-radius: 10px;
}

.border-bottom.custom-form-grid-section2 {
  margin-left: 20px;
  margin-right: 20px;
}

.popup-form-main-content {
  padding: 10px 20px;
}

.border-bottom .dx-toolbar {
  padding-top: 5px;
  padding-right: 15px;
  // background-color: #46CF64;
  margin-bottom: 5px !important;
}

.indent {
  padding-left: 19px;
}

.dx-widget.dx-checkbox.dx-checkbox-checked.popup-header-cancel-input {
  height: fit-content !important;
}

.dx-datagrid-headers.dx-datagrid-nowrap {
  background-color: #efefef;
}

.dx-item-content.dx-toolbar-item-content {
  font-size: 14px;
  font-weight: 400;
}

.popup-form .popup-group-form-item {
  padding-bottom: 15px;
}

//modal footer #1
.profile-modal-footer {
  text-align: end;
  margin-top: 10px;
}

//modal footer #2
.modal-footer {
  margin-top: 20px;
  float: right;
}

.action-btn {
  padding: 0px !important;
  margin-right: 5px;
  margin-top: 5px;

  .dx-icon {
    margin-right: 1px !important;
    margin-left: 0px !important;
  }
}

.form-group {
  margin-bottom: 20px;
}

.popup-scroll {
  max-height: 650px;
}

.document-viewer-title {
  font-size: 18px;
  font-weight: bolder;
  margin-bottom: 10px;
}

.dx-datagrid.dx-gridbase-container {
  border-color: white;
}

.dx-multiview.dx-swipeable.dx-tabpanel.dx-widget.dx-visibility-change-handler.dx-collection,
.dx-tabs.dx-widget.dx-visibility-change-handler.dx-collection.dx-tabs-expanded {
  border-color: white !important;
}

.dx-command-edit.dx-cell-focus-disabled.dx-datagrid-drag-action {
  background-color: #efefef;
  color: black;
}

td[role="columnheader"] {
  color: black;
}

#report-listing-btn-group {
  margin-top: 10px;
}

.dx-cell-focus-disabled.dx-master-detail-cell
  .dx-widget.dx-visibility-change-handler
  .dx-datagrid.dx-gridbase-container.dx-datagrid-borders {
  padding-top: 0px;

  .dx-datagrid-headers.dx-datagrid-nowrap
    .dx-datagrid-scrollable-simulated.dx-datagrid-content.dx-datagrid-scroll-container {
    background-color: #efefef;
  }
}

.dx-row.dx-column-lines.dx-group-row .dx-group-cell {
  font-weight: 500;
  color: #5f5f5f;
}

.popup-group-form-container.border-bottom {
  background-color: #efefef;
  border-radius: 10px;
  margin: 20px;
  padding: 10px;
}

.dx-datagrid-headers.dx-datagrid-nowrap {
  border-bottom: none;
  background-color: #efefef;
}

.popup-group-form-container.border-bottom.popup-form-datagrid-container
  .dx-widget.dx-visibility-change-handler
  .dx-datagrid.dx-gridbase-container {
  .dx-datagrid-rowsview.dx-datagrid-nowrap,
  .dx-datagrid-headers.dx-datagrid-nowrap {
    border-left: 1px solid #ddd;
    border-right: 1px solid #ddd;
  }

  .dx-datagrid-content.dx-datagrid-scroll-container {
    padding-top: 0px;
    padding-bottom: 0px;
  }
}

.lookup-datagrid.listing-datagrid.dx-widget.dx-visibility-change-handler
  .dx-row.dx-column-lines.dx-header-row
  td[role="columnheader"] {
  color: black;
}

.listing-page-add-btn.listing-btn.dx-widget.dx-dropdownbutton.dx-dropdownbutton-has-arrow
  .dx-button-content {
  color: white;
}

//Smaller Screen - Start
@media screen and (min-width: 300px) {
  .listing-page-title-container {
    display: flex;
  }

  .listing-datebox-container {
    display: flex;
  }
}

@media screen and (min-width: 700px) {
  .listing-page-title-container {
    display: flex;
  }

  .listing-datebox-container {
    display: flex;
  }
}

@media screen and (min-width: 300px) {
  .popup-form-item-container2 {
    grid-template-columns: auto;
  }

  .popup-form-item-container3 {
    grid-template-columns: auto;
  }

  .popup-form-item-container4 {
    grid-template-columns: auto;
  }
}

@media screen and (min-width: 800px) {
  .popup-form-item-container2 {
    grid-template-columns: 50% 50%;
  }

  .popup-form-item-container3 {
    grid-template-columns: 50% 50%;
  }

  .popup-form-item-container4 {
    grid-template-columns: 50% 50%;
  }

  .popup-group-item-container-flex {
    display: flex;
  }
}

@media screen and (min-width: 1200px) {
  .popup-form-item-container3 {
    grid-template-columns: 33.33% 33.33% 33.33%;
  }

  .popup-form-item-container4 {
    grid-template-columns: 25% 25% 25% 25%;
  }
}

@media screen and (max-width: 1200px) {
  .popup-form-item-container5 {
    grid-template-columns: auto;
  }

  .popup-form-item-container6 {
    grid-template-columns: auto;
  }
}

@media screen and (min-width: 800px) {
  .custom-form-grid-section1 {
    grid-template-columns: 50% 50%;
  }

  .custom-form-grid-section2 {
    grid-template-columns: 50% 50%;
  }

  .custom-form-grid-section3 {
    grid-template-columns: 50% 50% !important;
  }

  .custom-form-grid-section4 {
    grid-template-columns: 100% !important;
  }
}

@media screen and (min-width: 1200px) {
  .custom-form-grid-section1 {
    grid-template-columns: 40% 60% !important;
  }

  .custom-form-grid-section2 {
    grid-template-columns: 60% 40% !important;
  }

  .custom-form-grid-section3 {
    grid-template-columns: 70% 30% !important;
  }

  .custom-form-grid-section4 {
    grid-template-columns: 30% 70% !important;
  }

  .custom-form-grid-section5 {
    display: grid;
    grid-template-columns: 50% 50% !important;
  }

  .custom-form-item-container2 {
    grid-template-columns: 30% 70% !important;
  }

  .form-grid-layout {
    display: grid;
    grid-template-columns: 30% 70% !important;
    align-items: start;
    grid-auto-rows: minmax(auto, 1fr);
  }
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 1700px) {
  .manage-user-company-selection-container {
    height: 300px;
  }
}

@media screen and (max-width: 1000px) {
  .manage-user-company-selection-container {
    height: 270px;
  }
}

@media (max-width: 768px) {
  .terms-popup {
    width: 100% !important;
    /* Full width on smaller screens */
  }
}
.dx-toast-wrapper .dx-toast-content.dx-toast-custom {
  color: white !important;
  border-radius: 5px;
  padding: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 14px;
}

.einvoice-toast-flex-box {
  display: flex;
  align-items: center;
  justify-content: center;
}

.einvoice-toast-flex-box {
  color: white;
  border-radius: 5px;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: bold;
  font-size: 14px;
}

/* Completed Status (Green for Success) */
.einvoice-toast-flex-box.completed {
  background-color: #4caf50 !important; /* Success Green */
}

/* Pending Status (Yellow) */
.einvoice-toast-flex-box.pending {
  background-color: #ffd700 !important; /* Yellow */
}
