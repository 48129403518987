@import "../../themes/generated/variables.base.scss";

.login-form {
  margin-top: 45px;


  .link {
    text-align: center;
    font-size: 16px;
    font-style: normal;

    a {
      text-decoration: none;
    }
  }

  .form-text {
    margin: 10px 0;
    color: rgba($base-text-color, alpha($base-text-color) * 0.7);
  }
}


.auth-container {
  display: grid;
  width: 95%;

  margin-top: 25px;
  height: 80vh;
  grid-template-columns: 1.4fr 2fr; /* Adjusted column widths */
  grid-gap: 5rem; /* Maintain the gap between columns */
  font-family: 'Poppins', sans-serif;
  position: relative; /* Ensure this container can have its own stacking context */
  z-index: 1; /* Ensures this container is in front of the wave image */
}




.auth-container .description {
  display: flex;
	justify-content: flex-end;
	align-items: center;
  padding-left: 30px;
}

.auth-container  .description .title {
  font-size: 50px;
  font-weight: bold;
  color: #46CF64;
}

.auth-container  .description .subtitle {
  font-size: 20px;
  color: #333;
  margin-top: 10px;
  white-space: nowrap;
  word-wrap: break-word;
}

.authcontent-title {
  font-size: 36px;
  font-weight: bold;
  color: #46CF64;
  
}

.authcontent-subtitle {
  font-size: 20px;
  color: #333;
  margin-top: 10px; /* Adjust as needed */
  white-space: wrap;
  word-wrap: break-word;
  font-weight: 600;
  font-family: "Helvetica Neue", "Segoe UI", helvetica, verdana, sans-serif;
}
.auth-section-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.auth-content {
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 40px rgba(211, 211, 211, 0.8); /* Box shadow with blur radius */
  border-radius: 16px; /* Border radius */
  background-color: #ffffff;
  flex-grow: 1; 
  padding-top:0px;
  padding-bottom:15px;
  max-height: 600px;
  
}

.auth-footer {
  margin-top: 30px;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  z-index: 1; /* Place it above the wave image */
  background-color: transparent; /* Set background color to transparent */
}
.auth-footer-label {
  margin-right: 12px; /* Adjust the margin as per your design */
}



.auth-form {
  width: 100%; /* Occupy all available width */
  max-width: 780px; /* Limit maximum width */
  padding: 0 20px; /* Add padding for responsive layout */
}

.auth-content h2 {
  margin: 15px 0;
  color: #333;
  text-transform: uppercase;
  font-size: 2.9rem;
}

.auth-input-div {
  position: relative;
  display: grid;
  grid-template-columns: 2% 98%;
  margin: 25px 0;
  padding: 5px 0;
  
}

.auth-input-div.one {
  margin-top: 0;
}

.auth-i {
  color: #d9d9d9;
  display: flex;
  justify-content: center;
  align-items: center;
}

.auth-i i {
  transition: .3s;
}

.auth-input-div>div {
  position: relative;
  height: 45px;
}


.auth-input-div:before {
  right: 50%;
}

.auth-input-div:after {
  left: 5
}

.auth-input-div.focus:before,
.auth-input-div.focus:after {
  width: 50%;
}



.auth-input-div.focus>.auth-i>i {
  color: #38d39f;
}

.auth-div {
  margin-left:20px;
}


.auth-div .dx-texteditor-input-container .dx-texteditor-input , .dx-texteditor-with-label  {
 
  font-size: 16px;
  padding-top: 10px;
  font-family: 'Poppins', sans-serif !important;
}

.auth-div .dropdown .dx-dropdowneditor.dx-dropdowneditor-field-clickable .dx-texteditor-input {
  padding-top: 0px;
}

.auth-div .dropdown .dx-dropdowneditor-input-wrapper .dx-texteditor-input {
  font-size: 16px;
  font-family: 'Poppins', sans-serif !important;
}
.auth-input .dx-label {
  font-size: 14px;
  
}

.auth-input-div.pass {
  margin-bottom: 4px;
}

.auth-link {
  display: block;
  text-decoration: none;
  color: #999;
  font-size: 0.9rem;
  transition: .3s;
}

.auth-checkbox-div input[type="checkbox"] {
  margin-right: 8px; /* Adjust the margin as per your design */
}

.auth-flex-container {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}

.auth-checkbox-div {
  display: flex;
  align-items: center; /* Align items vertically centered */
}

.auth-forgot-password {
  text-decoration: none;
  color: #999;
  font-size: 0.9rem;
  transition: .3s;
}


.wave {
  position: fixed;
  bottom: 0;
  right: 0;
  height: 100%;
 
}
.auth-section-container {
   
  margin-top:1.2em;
}


@media screen and (min-width: 1601px) {
  .auth-container {
    grid-gap: 15rem; /* Adjust the grid gap for screens larger than 1600px */
    height: 76vh;
  }
  .auth-form {
    max-width: 850px; 
    padding: 0 0px;
    
  }
  .auth-section-container {
    margin-top:6em;
  }
}

@media screen and (max-width: 1100px) {


  .auth-container {
    width: 100vw;
    grid-template-columns: 1fr;
    justify-content: center;
    align-items: center;
  
  }
  .auth-container .description{
    display:none;
  }
  .auth-section-container {
   
    justify-content: center;
    align-items: center;
    margin-left: 0px;
    margin-top:0px;
  }
}
@media screen and (max-width: 960px) {
  .wave {
    display: none;
  }
}
@media screen and (max-width: 900px) {

  .auth-button {
    margin-top:12px;
    margin-bottom: 12px;
  }
}


@media screen and (max-width: 500px) {

  .auth-content {
    padding-top:20px;
    padding-bottom:40px;
    margin-left: 15px;
    margin-right: 15px;
  }
  

  
}


