//start plan selection 
.plan-selection-section {
    margin: 0 auto;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-family: 'Open Sans', sans-serif;
}

.plan-selection-wrapper {
    text-align: left;

}

.plan-selection-section__plans {
    display: flex;
    flex-direction: column;
    width: 80%;
    padding-right: 70px;
}

.plan-selection-section__plan {
    border: 1px solid #ccc;
    padding: 20px;
    margin-bottom: 10px;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
}

.plan-selection-section__plan-selected {
    background-color: #46CF64;
    color: white;
}

.price-green {
    color: #46CF64;
    font-size: 16px;
}

.plan-selection-section__plan-selected .price-green {
    color: white;
}

.plan-selection-section__plan-original-price {
    text-align: left; /* Align the text to the left */
    color: #8F8F8F; /* Set the font color to #8F8F8F */
    font-weight: normal;
    margin-top: 5px;
    display: block; /* Ensure the text takes full width and aligns left */
    position: relative;
    width: fit-content; /* Adjust the width to fit the content */
    margin-left: 5px;
}

.plan-selection-section__plan-original-price::before {
    content: '';
    position: absolute;
    width: 120%; /* Extend the line a bit beyond the width of the text */
    height: 1px; /* Thin line */
    background-color: red; /* Red color for the line-through */
    top: 50%; /* Position the line in the middle of the text */
    left: -5%; /* Shift the line a bit to the left to balance the extension */
    transform: translateY(-50%); /* Center the line vertically */
}
.plan-selection-section__plan-selected .plan-selection-section__plan-original-price {
    color: #E1E1E1;
}

.plan-selection-section__plan-name {
    font-weight: bold;
    font-size: 15px;
}

.plan-selection-section__plan-description {

    font-size: 14px;

}

.plan-selection-section__plan-price {
    text-align: right;
    font-weight: bold;
    font-size: 15px;
}

.plan-selection-section__users {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50%;

}

.plan-selection-section__users-title {
    font-size: 20px;
    margin-bottom: 10px;
    font-weight: bold;
}

.plan-selection-section__user-counter {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.plan-selection-section__user-decrement,
.plan-selection-section__user-increment {
    border: 1px solid #000;
    background: none;

    width: 40px;
    height: 40px;
    font-size: 15px;
    border-radius: 50%;
    cursor: pointer;
}

.plan-selection-section__user-decrement.disabled {
    color: #C5C5C5;
    cursor: not-allowed;
    border-color: #C5C5C5;
}

.plan-selection-section__user-number {
    font-size: 64px;
    margin: 0 40px;
    color: #46CF64;
    font-weight: bold;
}

.plan-selection-section__total-price {
    margin-bottom: 20px;
}

.plan-selection-section__actions {
    display: flex;
    gap: 10px;
}

.plan-selection-section__cancel-btn,
.plan-selection-section__continue-btn {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
}

.plan-selection-section__cancel-btn {
    background-color: #f0f0f0;
    color: #000;
}

.plan-selection-section__continue-btn {
    background-color: #46CF64;
    color: white;
}

// .price-highlight {
//     font-size: 15px;
//     font-weight: bold;
// }

.plan-disabled {
    opacity: 0.5;
    pointer-events: none;
}

.hr-vertical {
    width: 1px;
    background-color: #D1D1D1;
    border: none;
    margin: 0 10px;
    align-self: stretch;
}

.duration-selectbox-discount {
    display: block;
    font-size: 0.8rem;
    color: #B20020;
    margin-top: 5px;
    text-align: center;
    border: 1px solid #B20020;
    border-radius: 12px;
    padding: 3px;
    font-weight: 400 !important;
}
