.nav-header-container {
    background-color: white;
    position: fixed;
    display: flex;
    align-items: center; /* Center items vertically */
    justify-content: space-between; /* Spread items out horizontally */
    width: 100%;
    top: 0;
    z-index: 500;

}

.header-nav-button-container {
    position: relative;
}

.header-nav-button .dx-icon {
    font-size: 20px;
    color: #909896;
}

.header-logo-container {
    display: flex;
    width: 270px;
    justify-content: flex-start;
    background-color: transparent;
}

#nav-logo {
    margin-left: 30px;
}

.logo-container-white-true {
    background-color: white;
    transition: all 1.5s ease 0ms;
}

.subscription-alert-background {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-55%, -50%); /* Center horizontally and vertically */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1; 
    background-color: #FFFFFF;
    margin-top: 0;
    @media (max-width: 700px) {
        margin-top: 20px; /* Adjust this value as needed */
    }
}
.subscription-user-background {
   
    @media (max-width: 700px) {
        margin-top: 20px; /* Adjust this value as needed */
    }
}

.subscription-alert {
    background-color: #F8F3D6;
  
    color: #856404;
    padding: 5px 10px;
    font-size: 12px;
    display: flex;
    align-items: center;
    border-radius: 5px;
    max-width: 900px;
    overflow: hidden;
}

.subscription-expired-alert {
   
    background-color: #F8D6D6;
    color: #7A0303;
    padding: 5px 10px;
    font-size: 12px;
    display: flex;
    align-items: center;
    border-radius: 5px;
    max-width: 900px;
    overflow: hidden;
}
.subscription-user-expired-alert {
   
    background-color: #F8D6D6;
    color: #7A0303;
    padding: 5px 10px;
    font-size: 14px;
    display: flex;
    align-items: center;
    border-radius: 5px;

    margin-bottom:18px;
    overflow: hidden;
}
.subscription-alert-icon {
    font-size: 16px;
    margin-right: 8px;
}

.subscription-alert-message a {
    color: #007BFF;
    text-decoration: none;
}

.subscription-alert-message a:hover {
    text-decoration: underline;
}

.nav-header-right-section {
    position: relative;
    margin-left: auto;
    display: flex;
    align-items: center;
    background-color: #FFFFFF;
}

.subscription-expired-alert-icon {
    font-size: 16px;
    margin-right: 8px;
}

.subscription-expired-alert-message a {
    color: #007BFF;
    text-decoration: none;
}

.subscription-expired-alert-message a:hover {
    text-decoration: underline;
}
