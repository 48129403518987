.stocknegativeMessage-popup-body {
    margin-top: 17.5px;
    border-radius: 50px; 
}

.stocknegativeMessage-popup-items{
    color: black;
    font-size: 20px;
    justify-content: center;
}

.stocknegativeMessage-popup-header{
    color: black;
    font-size: 22.5px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 100%;
    justify-content: space-between;
    font-weight: 500;
    margin-top: -10px;
    position: relative;
}

.dx-icon-custom-styleOutstanding {
    font-size: 28.5px !important;  
    // margin-right: 50px;
    // transform: translate(-50% -50%);
}

.stocknegativeMessage-popup-content {
    font-size: 17px;    
    font-weight: 450;
    width: 100%;
}

.stocknegativeMessage-popup-footer {
    margin-top: 100px;
    display: grid;
    grid-template-columns: auto auto;
    column-gap: 20px;
    justify-content: end; 
}

.stocknegativeMessage-popup-footer .button-custom {
    width: 130px; 
    border-radius: 10px; 
}
.stocknegativeMessage-popup-cross{
    font-size: 20px;
    left: 92.5%;
    position: absolute;
}

.stocknegativeMessage-popup-cross .dx-icon-close{
    color: black !important;
    font-size: 22.5px !important;
}

.stocknegativeMessage-popup-limits{
    display: grid;
    
}

.stocknegative-border-bottom .dx-datagrid-headers {
    background-color: #ffffff !important;
    color: black;
    border-bottom: 2px solid #46CF64 !important;
   
  }
  