@import "../../themes/generated/variables.base.scss";

.footer {
  display: block;
  color: rgba($base-text-color, alpha($base-text-color) * 0.7);
  font-size: smaller;
  border-top: 0.5px solid #E6E6E6;
  padding-left: 5px;
  padding-top: 10px;
  padding-bottom: 30px;
  position: absolute;
  width: 96%;
  left: 2%;
  right: 2%;
}
