/* Style the tab */
.tab {
    overflow: hidden;
    // border: 1px solid #ccc;
    // background-color: #f1f1f1;
    border-bottom: 1px solid #ccc;
    margin-left: 32px;
    margin-right: 32px;
    margin-top: 20px;
  }
  
  /* Style the buttons inside the tab */
  .tab button {
    background-color: inherit;
    float: left;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 14px 16px;
    transition: 0.3s;
    font-size: 15px;
    color: black;
  }
  
  /* Change background color of buttons on hover */
  .tab button:hover {
    background-color: #ddd;
  }
  
  /* Create an active/current tablink class */
  .tab button.active {
    background-color: #EDFBF0;
  }

  .tablinks.active{
    color: #46CF64;
    border-bottom: solid 2px #46CF64;
  }
  
  /* Style the tab content */
  .tabcontent{

    padding: 6px 12px;

    .dx-datagrid-headers.dx-datagrid-nowrap{
      border: solid 1px #ddd;
      background-color: #EFEFEF;
    }

    .dx-datagrid-text-content.dx-text-content-alignment-left,
    .dx-datagrid-text-content.dx-text-content-alignment-right{
      color: black;
    }

    .dx-datagrid-rowsview.dx-datagrid-nowrap{
      border: 1px solid #ddd;
      border-top-style: none !important;
      border-bottom-style: none !important;
    }
  } 